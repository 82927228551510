import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker.js';

import App from './components/app/App.tsx';

// this tells the theme to avoid showing the ugly focus ring when not using keyboard to navigate
import { FocusStyleManager } from '@blueprintjs/core';
FocusStyleManager.onlyShowFocusOnTabs();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
